import  angular from 'angular';
import loginDialogView from './order/view/login-dialog.html';

function MainController(config, currentUser, $mdDialog, oauth, logger, settings, $pusher, $window) {
  const vm = this;
  const pusher = $pusher(window.pusherClient);
  const channel = pusher.subscribe(settings.pusherChannel);

  channel.bind('timetable-updated', () => $window.location.reload(true));
  channel.bind('store-data-updated', () => $window.location.reload(true));
  channel.bind('store-settings-updated', () => $window.location.reload(true));

  vm.currentUser = currentUser;
  vm.settings = settings;
  vm.styles = {
    header: {
      'background-color': settings.colors.headerBackground,
      'color': settings.colors.headerFont
    }
  };

  vm.signOut = function() {
    oauth.signOut();
  };

  vm.login = function(ev) {
    $mdDialog.show({
      controller: "order.LoginDialogController",
      template: loginDialogView,
      controllerAs: "vm",
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: false,
      fullscreen: false,
      locals: {
        "settings": settings
      }
    });
  };
}

export default [
  "common.config",
  "security.currentUser",
  "$mdDialog",
  "security.oauth",
  "common.logger",
  "settings",
  "$pusher",
  "$window",
  MainController
];
