import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, NativeSelect } from '@mui/material';

const TimezoneSelect = ({ control, timezones, timezone }) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="timezone-select">Timezone</InputLabel>
      <Controller
        name="timezone"
        control={control}
        defaultValue={timezone}
        render={({ field }) => (
          <NativeSelect
            {...field}
            id="timezone-select"
          >
            {timezones.map((timezone) => (
              <option key={timezone.id} value={timezone.id}>
                {timezone.displayName}
              </option>
            ))}
          </NativeSelect>
        )}
      />
    </FormControl>
  );
};

export default TimezoneSelect;
