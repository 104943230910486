import jwt_decode from "jwt-decode";

export class User {
  constructor(store) {
    this.store = store;
    this.USER = "user";
    this.ACCESS_TOKEN = "access_token";
  }

  getRole = () => this.store.getObject(this.USER).role;
  isAdmin = () => this.getRole() === "Admin";
  isGuest = () => this.getRole() === "Guest";
  getToken = () => this.store.getItem(this.ACCESS_TOKEN) || "";

  getGravatarUrl = () => this.store.getObject(this.USER).gravatarUrl;
  isLoggedIn = () => {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    const decoded = jwt_decode(token);
    return Date.now() < decoded.exp * 1000;
  };

  reset = (data) => {
    this.store.setItem(this.ACCESS_TOKEN, data.access_token);
    this.store.setObject(this.USER, data.userInfo);
  };

  signOut = () => {
    this.store.removeItem(this.ACCESS_TOKEN);
    this.store.removeItem(this.USER);
  };
}