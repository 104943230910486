export default class UpdatePostcodeController {
  constructor($mdDialog, orderSvc, settings, order) {
    this.$mdDialog = $mdDialog;
    this.orderSvc = orderSvc;
    this.settings = settings;
    this.order = order;
    this.postcode = "";
    this.error = {};
  }

  cancel() {
    this.$mdDialog.hide();
  }

  updatePostcode(form) {
    if (!form.$valid) {
      return false;
    }

    this.error.postcode = null;

    return this.orderSvc.addPostCode({
      orderId: this.order.id,
      postCode: this.postcode
    }).then(_ => {
        this.$mdDialog.hide();
    })
    .catch((err) => {
      this.error.postcode = {
        message: this.createMessage(err, 'Could update postcode')
      };
    });
  }

  createMessage(err, msg) {
    return err.data.message
      ? err.data.message
      : `Error: ${msg}`;
  }
}

UpdatePostcodeController.$inject = [
  "$mdDialog",
  "order.orderService",
  "settings",
  "order"
];
