import main from "./main";

angular
  .element(document)
  .ready(() => {
    let body = document.getElementsByTagName("body")[0];
    let spinner = document.getElementById('loader-container');

    let sub = setInterval(() => {
      if (document.getElementById('main-view')) {
        spinner.classList.add('hide-loader');
        clearInterval(sub);
      }
    }, 500);

    angular.bootstrap(body, [main.name], {
      strictDi: true
    });
  });
