import React from "react";
import { Controller } from "react-hook-form";
import {
  Switch,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Legend } from './StyledComponents';

export default function PaymentTypeToggle({
  control,
  setValue,
  watch,
}) {

  const selectedValue = watch("isCardDefault") ? "card" : "cash";

  const handleStatusChange = (field) => (event) => {
    setValue(field, event.target.checked);
  };

  const handleDefaultChange = (event) => {
    const value = event.target.value;
    if (value === "card") {
      setValue("isCardDefault", true);
      setValue("isCashDefault", false);
    } else {
      setValue("isCardDefault", false);
      setValue("isCashDefault", true);
    }
  };

  return (
    <>
      <FormGroup>
        <Legend>Active Payments</Legend>

        <FormControlLabel
          control={
            <Controller
              name="isCardActive"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={handleStatusChange("isCardActive")}
                  color="primary"
                />
              )}
            />
          }
          label="Card"
        />

        <FormControlLabel
          control={
            <Controller
              name="isCashActive"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={handleStatusChange("isCashActive")}
                  color="primary"
                />
              )}
            />
          }
          label="Cash"
        />
      </FormGroup>

      <FormGroup>
        <Legend>Default Payment</Legend>

        <RadioGroup
          value={selectedValue}
          onChange={handleDefaultChange}
          row
        >
          <FormControlLabel
            value="card"
            control={<Radio />}
            label="Card"
          />
          <FormControlLabel
            value="cash"
            control={<Radio />}
            label="Cash"
          />
        </RadioGroup>
      </FormGroup>
    </>
  );
}
