export default class SelectModifierController {
  constructor(logger, $mdDialog, menu) {
    this.$mdDialog = $mdDialog;
    this.logger = logger;
    this.menu = menu;
    this.selectedTab = 0;

    this.levels = this.menu.modifierLevels.map(level => ({
      ...level,
      totalSelected: 0,
      modifiers: level.modifiers.map(modifier => ({
        ...modifier,
        isSelected: false
      }))
    })).sort((x, y) => {
      if (parseInt(x.level, 10) > parseInt(y.level, 10)) {
        return 1;
      }

      if (parseInt(y.level, 10) > parseInt(x.level, 10)) {
        return -1;
      }

      return 0;
    });
  }

  selectedMoreThanAllowed() {
    return this.levels.find(
      l => l.modifiers.filter(m => m.isSelected).length > l.maxAllowed
    );
  }

  switchTab(_level) {
    if (!_level) {
      return;
    }
    const level = this.levels.find(l => l.level === _level.level);
    const totalSelected = level.modifiers.filter(m => m.isSelected).length;
    if ((totalSelected === _level.maxAllowed) && ((this.selectedTab + 1 ) < this.levels.length)) {
      this.selectedTab = this.selectedTab + 1;
    }

  }

  canAdd() {
    return !this.selectedMoreThanAllowed();
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  select() {
    this.$mdDialog.hide(
      this.levels.flatMap(
        l => l.modifiers.filter(m => m.isSelected)
      )
    );
  }
}

SelectModifierController.$inject = [
  "common.logger",
  "$mdDialog",
  "menu"
];
