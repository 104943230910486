import axios from "axios";
import SessionStore from "./store";
import {User} from "./user";

const user = new User(new SessionStore());

axios.interceptors.request.use(function (config) {
  if (user.isLoggedIn()) {
    config.headers.Authorization = `Bearer ${user.getToken()}`;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default axios;
