export default class LoginController {
  constructor(oauth, logger, loginRedirect) {
    this.oauth = oauth;
    this.logger = logger;
    this.loginRedirect = loginRedirect;
    this.userName = "";
    this.password = "";
  }

  login(form) {
    if (!form.$valid) {
      this.logger.showError("Invalid user name or password");
      return;
    }
    this.oauth
      .login(this.userName, this.password)
      .then(() => {
        this.loginRedirect.redirectPostLogin();
      })
      .catch((err) => {
        this.logger.showError("Could not log in.");
      });
  }
}

LoginController.$inject = [
  "security.oauth", "common.logger", "security.loginRedirect"
];
