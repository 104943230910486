function ForgotPasswordController(accountSvc, logger, $state) {
  var vm = this;
  vm.email = "";

  vm.sendResetLink = sendResetLink;
  vm.canSendLink = canSendLink;
  vm.goBack = goBack;
  vm.linkSent = false;
  vm.loading = false;

  function canSendLink(form) {
    return form.$valid === true;
  }

  function goBack() {
    window.history.back();
  }

  function sendResetLink(form) {
    if (!form.$valid) {
      logger.showError("Invalid user name or password");
      return;
    }
    vm.loading = true;

    var req = { email: vm.email };
    accountSvc
      .sendPasswordResetLink(req)
      .then(() => {
        vm.linkSent = true;
        logger.showInfo("Reset link sent; check your mail.");
      })
      .catch((err) => {
        vm.linkSent = false;
        logger.showError("Could not send reset link.");
      })
      .finally(() => {
        vm.loading = false;
      });
  }
}

export default [
  "userMgt.accountService",
  "common.logger",
  "$state",
  ForgotPasswordController
];
