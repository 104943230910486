function DeliveryService($http, $q, config) {
  function getDeliveryChargeByPostCode(postCode, total) {
    return $http.get(`${config.apiUrl}/delivery/areas/${postCode}/order/${total}/charge`)
      .then((res) => {
        return res.data;
      });
  }

  function isWithinDeliveryArea(postCode) {
    return $http.get(`${config.apiUrl}/delivery/areas/${postCode}/covered`)
      .then((response) => {
        return response.data;
      });
  }

  return {
    getDeliveryChargeByPostCode : getDeliveryChargeByPostCode,
    isWithinDeliveryArea : isWithinDeliveryArea
  };
}

export default ["$http", "$q", "common.config", DeliveryService];
