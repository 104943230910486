import SessionStore from '../common/store';
import OAuth from "./oauth";
import CurrentUser from "./current-user";
import addToken from "./add-token";
import loginRedirect from "./login-redirect";

export default angular
  .module("security", ["common"])
  .service("security.sessionStore", SessionStore)
  .service("security.currentUser", CurrentUser)
  .factory("security.oauth", OAuth)
  .factory("security.addToken", addToken)
  .factory("security.loginRedirect", loginRedirect)
  .config([
    "$httpProvider",
    function($httpProvider) {
      $httpProvider.interceptors.push("security.addToken");
      $httpProvider.interceptors.push("security.loginRedirect");
    }
  ]);
