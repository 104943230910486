import dayjs from 'dayjs';
import timezone from "dayjs/plugin/timezone";
import dayjsUTC from 'dayjs/plugin/utc';
import {config} from "./config";
import "dayjs/locale/en-gb";

dayjs.extend(dayjsUTC);
dayjs.extend(timezone);

dayjs.tz.setDefault(config.ianaTimezone || 'Europe/London');
dayjs.locale('en-gb');

export default dayjs;