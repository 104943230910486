import angular from 'angular';

function worldpayGateWay() {
  var self = this;
  var instId, accId1, currency, embedGateway, hideCurrency, actionUrl, testMode;

  var formTemplate =
    "<form id=\"checkoutForm\" name=\"worldpay\" action=\"{{actionUrl}}\" method=\"POST\">" +
      "<input type=\"hidden\" name=\"amount\"  value=\"{{total}}\">" +
      "<input type=\"hidden\" name=\"testMode\" value=\"{{testMode}}\">" +
      "<input type=\"hidden\" name=\"instId\"  value=\"{{instId}}\">" +
      "<input type=\"hidden\" name=\"cartId\" value=\"{{cartId}}\">" +
      "<input type=\"hidden\" name=\"hideCurrency\" value=\"{{hideCurrency}}\">" +
      "<input type=\"hidden\" name=\"currency\" value=\"{{currency}}\">" +
      "<input type=\"hidden\" name=\"M_items\" value=\"{{sessionId}}\">" +
      "<input type=\"hidden\" name=\"desc\" value=\"{{orderRemark}}\">" +
      "<input type=\"hidden\" name=\"address1\" value=\"{{ address.houseNo + ' ' + address.flatNo + ' ' + address.building}}\">" +
      "<input type=\"hidden\" name=\"address2\" value=\"{{address.road}}\">" +
      "<input type=\"hidden\" name=\"town\" value=\"{{address.town}}\">" +
      "<input type=\"hidden\" name=\"postcode\" value=\"{{address.postCode}}\">" +
      "<input type=\"hidden\" name=\"country\" value=\"{{address.country}}\">" +
      "<input type=\"hidden\" name=\"email\" value=\"{{address.email}}\">" +
      "</form>";

  self.$get = [
    "common.logger",
    "$http",
    "$interpolate",
    "common.config",
    "common.encoder",
    getewayFactory
  ];

  self.configure = function(options) {
    embedGateway = options.embed;
    instId = options.instId;
    currency = options.currency;
    hideCurrency = options.hideCurrency;
    actionUrl = options.actionUrl;
    testMode = options.testMode;
    return this;
  };

  function getewayFactory(logger, $http, $interpolate, config, encoder) {
    return {
      pay: function(cart, cartId) {
        var formItems = angular.extend({
          instId: instId,
          cartId: cartId,
          hideCurrency: hideCurrency,
          testMode: testMode,
          currency: currency,
          actionUrl: actionUrl
        }, cart);

        let form = angular.element($interpolate(formTemplate, true, false)(formItems));
        let body = angular.element(document).find('body');
        body.append(form);

        form[0].submit();
        form.remove();
      }
    };
  }
}

export default [worldpayGateWay];