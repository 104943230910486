import React from "react";
import { Box } from "@mui/material";
import { Topbar } from "app/common/Topbar";
import { config } from "app/common/config";

import "./RefundPolicy.css";

export function RefundPolicyView() {
  return (
    <React.Fragment>
      <Topbar title={"Refund Policy"} />
      <Box sx={{ p: "10px", overflowY: 'auto' }}>
        <p>Thank you for shopping at {config.websiteUrl} If you are not entirely satisfied with your purchase, we're happy to help.</p>

        <h4>Refunds</h4>
        <p>
          If you cancel your order within 5 minutes of placing your order than we will be able to cancel and refund your orders.
          In other case if there is any problem on our service(delivery late) and if you are not happy than we will refund your payment.
          we will initiate a refund to your credit card (or original method of payment). You will receive the credit within 7 Working days, depending
          on your card issuer's policies.
        </p>

        <h4>Contact Us </h4>
        <p>If you have any questions on how to return your item to us, Please contact us. </p>

      </Box>
    </React.Fragment>
  )
}