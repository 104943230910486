function logger($mdToast, $log) {
  const clsMap = {
    'warn': 'md-warn',
    'info': 'md-primary',
    'error': 'md-accent'
  };
  
  const showInfo = function (msg) {
    return showMessage(msg, 'info');
  };

  const showError = function (msg) {
    return showMessage(msg, 'error');
  };

  const showWarning = function (msg) {
    return showMessage(msg, 'warn');
  };

  function showMessage(msg, type) {
    
    return $mdToast.show(
      $mdToast.simple()
      .textContent(msg)
      .action('OK')
      .position('top, right')
      .hideDelay(3000)
      .highlightAction(true)
      .highlightClass(clsMap[type])
    );
  }

  return {
    showInfo: showInfo,
    showWarning: showWarning,
    showError: showError
  };
}

export default ["$mdToast", "$log", logger];
