function addVersion($window, $q) {
  const {appConfig} = $window.posCube;
  function request(config) {
    config.headers["X-PosCube-Client-Version"] = appConfig.version;
    return $q.when(config);
  }

  return {
    request: request
  };
}

export default ['$window', '$q', addVersion];
