function StripeSuccessController(config, orderSvc, $stateParams) {
  var vm = this;
  vm.orderState = 'processing';
  vm.error = { message: '' };


  function init() {
    orderSvc
      .success($stateParams.orderId, $stateParams.session_id)
      .then(() => {
        vm.orderState = 'success';
      })
      .catch((err) => {
        vm.error = {
          message: err.data && err.data.message ? err.data.message : 'Sorry! we could not process the payment for your order',
          orderId: $stateParams.orderId
        };
        vm.orderState = 'failed';
      });
  }

  init();
}

export default [
  "common.config",
  "order.orderService",
  "$stateParams",
  StripeSuccessController 
];