import React, { useMemo } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormGroup, Grid } from "@mui/material";
import { Legend } from './StyledComponents';

function CloseStoreSwitch({ watch, setValue }) {
  const isDeliveryActive = watch("isDeliveryActive");
  const isCollectionActive = watch("isCollectionActive");
  const allowPreOrder = watch("allowPreOrder");

  const isStoreOpen = useMemo(() => {
    return isDeliveryActive || isCollectionActive || allowPreOrder;
  }, [isDeliveryActive, isCollectionActive, allowPreOrder]);

  const handleStoreOpenChange = (event) => {
    const isOpen = event.target.checked;
    setValue("isDeliveryActive", isOpen);
    setValue("isCollectionActive", isOpen);
    setValue("allowPreOrder", isOpen);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <FormGroup>
          <Legend>Store Status</Legend>
          <FormControlLabel
            control={
              <Switch
                checked={isStoreOpen}
                onChange={handleStoreOpenChange}
                color="primary"
                inputProps={{ "aria-label": "Open/Close Store" }}
              />
            }
            label={isStoreOpen ? 'Open': 'Closed'}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default CloseStoreSwitch;
