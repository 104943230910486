const {appConfig} = window.posCube;

const paymentProvider = {
  none: 0,
  worldPay: 1,
  stripe: 2
};

export const config = {
  ...appConfig,
  paymentProvider
};