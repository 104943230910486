function ResetPasswordController(accountSvc, logger, $stateParams, $state) {
  var vm = this;
  vm.userId = $stateParams.userId;
  vm.code = $stateParams.code;

  vm.password = "";
  vm.confirmPassword = "";

  vm.resetPassword = resetPassword;
  vm.canResetPassword = canResetPassword;
  vm.goBack = goBack;
  vm.loading = false;

  function canResetPassword(form) {
    return form.$valid === true;
  }

  function goBack() {
    window.history.back();
  }

  function resetPassword(form) {
    if (!form.$valid) {
      logger.showError("Invalid user name or password");
      return;
    }

    vm.loading = true;
    var req = { userId: vm.userId, password: vm.password, code: vm.code };

    accountSvc
      .resetPassword(req)
      .then(() => {
        logger.showInfo("Password reset successful.");
        $state.go('app.login');
      })
      .catch((err) => {
        logger.showError("Could not reset password.");
      })
      .finally(_ => {
        vm.loading = false;
      });
  }
}

export default [
  "userMgt.accountService",
  "common.logger",
  "$stateParams",
  "$state",
  ResetPasswordController
];
