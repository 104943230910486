import  get from 'lodash-es/get';
import changeAddressDialogView from './view/change-address-dialog.html';
import updatePersonalInfoDialogView from './view/update-personal-info-dialog.html';

class OrderState {
    constructor() {
        this.failedMessage = '';
        this.placingMessage = '';
        this.isPlacing = false;
        this.isPlaced = false;
        this.isFailed = false;
    }

    failed(msg) {
        this.placingMessage = '';
        this.isPlacing = false;
        this.isPlaced = false;
        this.isFailed = true;
        this.failedMessage = msg;
    }

    placed() {
        this.failedMessage = '';
        this.placingMessage = '';
        this.isFailed = false;
        this.isPlacing = false;
        this.isPlaced = true;
    }

    placing(msg) {
        this.failedMessage = '';
        this.isFailed = false;
        this.isPlaced = false;
        this.isPlacing = true;
        this.placingMessage = msg;
    }
}

function CheckoutController(
    orderSvc,
    config,
    $state,
    currentUser,
    worldpayGateway,
    $scope,
    deliverySvc,
    $mdDialog,
    storeSettings,
    $mdSidenav,
    $mdMedia,
    $stateParams,
    $window,
    data) {

    const vm = this;

    vm.orderState = new OrderState();
    vm.storeAddress = storeSettings.storeAddress;
    vm.order = data;
    vm.placeOrder = placeOrder;
    vm.canPlaceOrder = canPlaceOrder;
    vm.backToOrder = backToOrder;
    vm.uddateAddress = updateAddress;
    vm.updatePersonalInfo = updatePersonalInfo;

    vm.retry = (e) => {
        e.preventDefault();
        $window.location.reload();
    };

    vm.applyDiscount = function (id) {
        orderSvc.applyDiscount({orderId: vm.order.id, discountId: id})
            .then(_vm => {
                vm.order = angular.extend(vm.order, _vm);
            })
            .catch(err => {
                vm.order.errors.push({
                    type: 'DISCOUNT',
                    message: get(err, 'data.message', 'Could not apply discount. Unknown error occured')
                });
            });
    };

    vm.applyCoupon = function (coupon) {
        if (!coupon) {
            return;
        }

        orderSvc.applyCoupon({orderId: vm.order.id, coupon: coupon.trim()})
            .then(_vm => {
                vm.order = angular.extend(vm.order, _vm);
            })
            .catch(err => {
                vm.order.errors.push({
                    type: 'DISCOUNT',
                    message: get(err, 'data.message', 'Could not apply coupon. Unknown error occured')
                });
            });
    };


    vm.isMobile = function () {
        return $mdMedia('xs');
    };

    vm.toggleSidenav = () => {
        $mdSidenav("checkout-sidenav").toggle();
    };


    vm.paymentTypes = getPaymentTypes();
    vm.getErrors = (type) => vm.order.errors.filter(x => x.type === type);
    vm.showSubtotal = () => vm.order.totalDiscount > 0 || vm.order.deliveryCharge > 0;

    function getPaymentTypes() {
        return data.paymentTypes.map(x => ({
            ...x,
            icon: x.type === "Cash" ? "fa-money" : "fa-credit-card",
            title: `${x.type} ${x.isActive ? '' : '(Inactive)'}`
        }));
    }

    function updatePersonalInfo(ev) {
        ev && ev.preventDefault();
        $mdDialog
            .show({
                controller: "order.UpdatePersonalInfoDialogController",
                template: updatePersonalInfoDialogView,
                controllerAs: "vm",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    "order": vm.order,
                }
            })
            .then((info) => orderSvc.updatePersonalInfo({
                ...info,
                orderId: vm.order.id
            }))
            .then(_vm => {
                vm.order = angular.extend(vm.order, _vm);
            });
    }


    function updateAddress(ev) {
        ev && ev.preventDefault();
        $mdDialog
            .show({
                controller: "order.ChangeAddressDialogController",
                template: changeAddressDialogView,
                controllerAs: "vm",
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    "orderId": vm.order.id,
                    "address": vm.order.address,
                    "orderSvc": orderSvc
                }
            })
            .then(_vm => {
                vm.order = angular.extend(vm.order, _vm);
            });
    }

    function backToOrder() {
        $state.go("app.order");
    }

    function createRequest() {
        return ({
            orderId: vm.order.id,
            instruction: vm.order.instruction,
            preferredTime: vm.order.preferredTime,
            paymentMethod: vm.order.paymentMethod,
            total: vm.order.total
        });
    }

    function canPlaceOrder() {
        return vm.order.errors.length <= 0;
    }

    function placeOrder() {
        vm.orderState.placing('Your order is being processed...');
        return orderSvc
            .placeOrder(createRequest())
            .then((resp) => {
                if (resp.paymentMethod === 'Cash') {
                    vm.orderState.placed();
                    return;
                }

                if (resp.paymentMethod === 'Card'
                    && resp.paymentProvider === config.paymentProvider.worldPay) {
                    return worldpayGateway.pay(vm.order);
                }

                if (resp.paymentMethod === 'Card'
                    && resp.paymentProvider === config.paymentProvider.stripe) {
                    const stripe = Stripe(storeSettings.stripePublishableKey);
                    return stripe.redirectToCheckout({sessionId: resp.sessionId})
                        .then((result) => {
                            // If `redirectToCheckout` fails due to a browser or network
                            // error, you should display the localized error message to your
                            // customer using `error.message`.
                            if (result.error) {
                                vm.orderState.failed(result.error.message);
                            }
                        });
                }
            })
            .catch((err) => {
                let message = err.message ? err.message : "";
                if (err.data && err.data.message) {
                    message = err.data.message;
                }
                vm.orderState.failed(message ? message : "Something unexpected happend; contact the resturant.");
            });
    }
}

export default [
    "order.orderService",
    "common.config",
    "$state",
    "security.currentUser",
    "paymentGateway.worldPay",
    "$scope",
    "order.deliveryService",
    "$mdDialog",
    "storeSettings",
    "$mdSidenav",
    "$mdMedia",
    "$stateParams",
    "$window",
    "data",
    CheckoutController
];
