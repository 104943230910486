import React, {useEffect, useState} from "react";
import { config } from "../../common/config";
import axios from "../../common/axios";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Alert,
  AlertTitle,
  Grid,
  TextField,
  Toolbar,
  AppBar,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  Avatar,
  Button,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { red } from '@mui/material/colors';
import "./Profile.css";

const getProfile = () => axios.get(`${config.apiUrl}/accounts/current`).then(res => res.data);
const deactiveAccount = (payload) => axios.delete(`${config.apiUrl}/accounts/current`, { data: payload }).then(res => res.data);

function Topbar() {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Profile
        </Typography>
      </Toolbar>
    </AppBar>
  )
}


function ProfileCard({ data }) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="fullName"
                label="Full Name"
                name="fullName"
                autoComplete="full-name"
                disabled
                value={data.fullName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                disabled
                value={data.email}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled
          >
            Update
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

function DeactivateAcount({ $state }) {
  const queryClient = useQueryClient();
  const [signOut, setSignOut] = useState(false);
  const { mutate, reset, isLoading, isError, isSuccess, error } = useMutation(deactiveAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries("profile");
      setSignOut(true);
    }
  });

  useEffect(() => {
    let timer = null;
    if (signOut) {
      timer = setTimeout(() => $state.go("app.logout"), 5000);
    }
    return () => timer && clearTimeout(timer);

  }, [signOut]);

  const handleDeactivate = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    mutate({ email: data.get('email'), hasConsent: data.get('consent') === 'on' ? true : false })
  };

  if (isLoading) {
    return (
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <span>Deactivating...!</span>
        </CardContent>
      </Card>
    )
  }

  if (isError) {
    return (
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Alert
            severity="error"
            action={
              <Button color="inherit" size="small" onClick={() => reset()}>
                Retry
              </Button>
            }
          >
            <AlertTitle>Error</AlertTitle>
            {error.response.data.message}
          </Alert>
        </CardContent>
      </Card>
    )
  }

  if (isSuccess) {
    return (
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Typography component="div" sx={{ flexGrow: 1 }}>
            Account deletion request received! You will receive emails confirming the request and subsequent removal of data. We will be logging you out soon.
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Box component="form" noValidate onSubmit={handleDeactivate} sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup >
                <FormControlLabel control={<Checkbox name="consent" />} label="I want to delete my account" />
              </FormGroup>
            </Grid>
          </Grid>
          <Button
            color="warning"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Delete Account
          </Button>
        </CardContent>
      </Card>
    </Box>
  )
}

export function ProfileView({$state}) {
  const { isLoading, isError, data, error } = useQuery('profile', getProfile);

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <React.Fragment>
      <Topbar />
      <Container maxWidth="sm">
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Avatar sx={{ m: 1, bgcolor: red[500] }} aria-label="avatar" src={data.gravatarUrl} sx={{ width: 56, height: 56 }} />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {data.fullName}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <ProfileCard data={data} />
          </Grid>
          <Grid item>
            <DeactivateAcount $state={$state} />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}
