import "angular";
import "angular-animate";
import "angular-aria";
import "angular-messages";
import "angular-ui-router";
import "angular-material";

import "pusher-js";
import "pusher-angular";

import "font-awesome/css/font-awesome.css";
import "angular-material/angular-material.scss";

import "./style/app.css";

import common from "./common";
import paymentGateway from "./payment-gateway";
import order from "./order";
import security from "./security";
import userMgt from "./user-management";
import reservation from "./reservation";
import admin from "./admin";

import MainController from "./main-controller";
import addVersion from "./client-version";
import reloadLatest from "./reload-latest";
import mainView from "./main-view.html";

import HomeController from "./home-controller";

import contactUsView from "./contact-us.html";
import dayjs from './common/dayjs';

import { TermsAndConditionsView } from "./TermsConditions";
import { RefundPolicyView } from "./RefundPolicy";
import withAngular from "app/common/with-angular";



const { appConfig } = window.posCube;
window.pusherClient = new window.Pusher(appConfig.pusherAppKey, { cluster: appConfig.pusherCluster });

let modules = ["ngMaterial", "ngMessages", "ui.router", "pusher-angular"]
  .concat([
    `${order.name}`,
    `${security.name}`,
    `${userMgt.name}`,
    `${common.name}`,
    `${paymentGateway.name}`,
    `${reservation.name}`,
    `${admin.name}`
  ]);

export default angular
  .module("xencube", modules)
  .filter("trustAsResourceUrl",
    [
      "$sce", function ($sce) {
        return function (val) {
          return $sce.trustAsResourceUrl(val);
        };
      }
    ])
  .component("xsRefundPolicyView", withAngular(RefundPolicyView))
  .component("xsTermsAndConditionsView", withAngular(TermsAndConditionsView))
  .factory("xencube.addVersion", addVersion)
  .factory("xencube.reloadLatest", reloadLatest)
  .controller("xencube.MainController", MainController)
  .controller("xencube.HomeController", HomeController)
  .config(config)
  .run(run);

function run($transitions, $currentUser) {
  $transitions.onBefore({},
    function (trans) {
      const isAdmin = $currentUser.getRole() === "Admin";
      const isAdminState = trans.to().name === "app.admin";
      if (isAdminState && !isAdmin) {
        return trans.router.stateService.target("app.login");
      }
    });
}

run.$inject = [
  "$transitions",
  "security.currentUser"
];

function config($mdThemingProvider,
  $stateProvider,
  $urlRouterProvider,
  $mdDateLocaleProvider,
  $httpProvider,
  worldPay) {
  $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  $httpProvider.interceptors.push("xencube.addVersion");
  $httpProvider.interceptors.push("xencube.reloadLatest");

  $mdDateLocaleProvider.formatDate = function (date) {
    return date ? dayjs(date).format('DD/MM/YYYY') : '';
  };

  $mdDateLocaleProvider.parseDate = function (dateString) {
    const m = dayjs(dateString, 'DD/MM/YYYY', true);
    return m.isValid() ? m.toDate() : new Date(NaN);
  };

  $mdDateLocaleProvider.isDateComplete = function (dateString) {
    dateString = dateString.trim();
    // Look for two chunks of content (either numbers or text) separated by delimiters.
    const re = /^(([a-zA-Z]{3,}|[0-9]{1,4})([ .,]+|[/-]))([a-zA-Z]{3,}|[0-9]{1,4})/;
    return re.test(dateString);
  };

  $urlRouterProvider.otherwise("/");

  $mdThemingProvider
    .theme("default")
    .primaryPalette(appConfig.colors.palette);

  $stateProvider
    .state("app",
      {
        url: "",
        abstract: true,
        views: {
          '': {
            template: mainView,
            controller: "xencube.MainController",
            controllerAs: "vm"
          }
        },
        resolve: {
          settings: [
            "common.config", function (cfg) {
              return cfg.getStoreSettings();
            }
          ]
        }
      })
    .state("app.contact",
      {
        url: "/contact",
        resolve: {
          "schedule": ["common.config", "$http", function (config, $http) {
            const url = `${config.apiUrl}/stores/default/schedule`;
            return $http.get(url).then(res => res.data);
          }]
        },
        controller: [
          "settings", "schedule", function (settings, schedule) {
            const vm = this;
            schedule.days.forEach(x => x.openHours.forEach(oh => {
              oh.start.hour = String(oh.start.hour).padStart(2, 0);
              oh.start.minute = String(oh.start.minute).padStart(2, 0);
              oh.end.hour = String(oh.end.hour).padStart(2, 0);
              oh.end.minute = String(oh.end.minute).padStart(2, 0);
            }));

            vm.settings = settings;
            vm.schedule = schedule;
            vm.primaryColor = {
              color: settings.colors.primary
            };
          }
        ],
        controllerAs: "vm",
        template: contactUsView,
      })
    .state("app.refundPolicy", {
      url: "/refund-policy",
      component: 'xsRefundPolicyView'
    })
    .state("app.termsAndConditions", {
      url: "/terms-conditions",
      component: 'xsTermsAndConditionsView'
    });

  worldPay.configure({
    embed: false,
    hideCurrency: true,
    currency: "GBP",
    instId: appConfig.worldPayInstId,
    testMode: appConfig.worldPayTestMode,
    actionUrl: appConfig.worldPayActionUrl
  });
}

config.$inject = [
  "$mdThemingProvider",
  "$stateProvider",
  "$urlRouterProvider",
  "$mdDateLocaleProvider",
  "$httpProvider",
  "paymentGateway.worldPayProvider"
];
