import React from "react";
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dayjs from "app/common/dayjs";

export function withLocalization(Component) {
  return (props) => (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateLibInstance={dayjs}
      locale="en-gb"
    >
      <Component {...props}/>
    </LocalizationProvider>
  );
}