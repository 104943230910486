function encoder() {

  function encodeId(lines) {
    var encodedList = [];

    lines.forEach((line) => {
      var encoded = [];
      var id = line.menuItemId.toString().toUpperCase();

      for (var i = 0; i < id.length; i++) {
        var code = codeup1.indexOf(id[i]);
        if (code < 0) {
          throw new Error("Item code error for '" + id + "'' - invalid id character '" + id[i] + "' at position " + i + "");
        }
        if (code > 9) {
          code = codeup2[code];
        }
        encoded.push(code);
      }

      encoded.push("-");
      encoded.push(line.quantity.toString().length);
      encoded.push(line.quantity);
      encodedList.push(encoded);

    });

    var cartIdParts = [];
    for (var i = 0; i < encodedList.length; i++) {
      cartIdParts.push(encodedList[i].join(""));
    }
    return cartIdParts.join("");
  }

  function decodeId(idStr) {
    var codes = [];
    var parts = idStr.split("-");
    for (var i = 0, j = 1; j < parts.length; i++, j++) {
      var code = [];
      for (var p in parts[i]) {
        var id = parts[i][p];
        if ("0123456789".indexOf(id) < 0) {
          id = codeup2.indexOf(id);
        }
        code.push(codeup1[id]);
      }
      var qlen = parseInt(parts[j][0], 10);
      var qty = parseInt(parts[j].substr(1, qlen), 10);
      codes.push({ id: code.join(""), quantity: qty });
      parts[j] = [].slice.call(parts[j], qlen + 1);
    }
    return codes;
  }

  return {
    encodeId: encodeId,
    decodeId: decodeId
  };
}

export default [encoder];