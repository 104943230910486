function loginRedirect($q, $location) {
    let lastPath = "/";

    const responseError = function (response) {
        if (response.status === 401) {
            lastPath = $location.path();
            $location.path("/login");
        }
        return $q.reject(response);
    };

    const redirectPostLogin = function () {
        $location.path(lastPath);
        lastPath = "/";
    };

    return {
        responseError: responseError,
        redirectPostLogin: redirectPostLogin
    };
}

export default ["$q", "$location", loginRedirect];
