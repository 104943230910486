import "angular-sanitize";
import OrderController from "./order-controller";
import OrderService from "./order-service";
import DiscountService from "./discount-service";
import DeliveryService from "./delivery-service";
import Order from "./order";
import CheckoutController from "./checkout-controller";
import LoginDialogController from "./login-dialog-controller";
import ChangeAddressDialogController from "./change-address-dialog-controller";
import SelectModifierDialogController from "./select-modifer-controller";
import UpdatePersonalInfoDialogController from "./update-personal-info-dialog-controller";
import UpdatePostcodeController from "./update-poscode-controller";
import MenuItemDirective from "./menu-item-directive";
import StripeSuccessController from "./stripe-success-controller";

import orderView from './view/order.html';
import stripeSuccessView from './view/stripe-success.html';
import stripeCancelView from './view/stripe-cancel.html';

import checkoutView from './view/checkout.html';

export default angular
    .module("order", ["ngMaterial", "ngMessages", "ngSanitize", "ui.router", "pusher-angular", "paymentGateway", "security", "common", "userMgt"])
    .service("order.orderService", OrderService)
    .service("order.discountService", DiscountService)
    .service("order.deliveryService", DeliveryService)
    .service("order.order", Order)
    .directive("xcMenuItems", MenuItemDirective)
    .directive('bindHtmlCompile', ['$compile', function ($compile) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.$watch(function () {
                    return scope.$eval(attrs.bindHtmlCompile);
                }, function (value) {
                    element.html(value);
                    $compile(element.contents())(scope);
                });
            }
        };
    }])
    .controller("order.OrderController", OrderController)
    .controller("order.StripeSuccessController", StripeSuccessController)
    .controller("order.CheckoutController", CheckoutController)
    .controller("order.LoginDialogController", LoginDialogController)
    .controller("order.ChangeAddressDialogController", ChangeAddressDialogController)
    .controller("order.SelectModifierDialogController", SelectModifierDialogController)
    .controller("order.UpdatePersonalInfoDialogController", UpdatePersonalInfoDialogController)
    .controller("order.UpdatePostcodeController", UpdatePostcodeController)
    .config(["$stateProvider", config]);

function config($stateProvider) {
    $stateProvider
        .state("app.order", {
            url: "/",
            template: orderView,
            controller: "order.OrderController",
            controllerAs: "vm",
            resolve: {
                order: [
                    "order.orderService", "order.order", function (svc, order) {
                        return svc.getDraftOrder()
                            .then(darftOrder => order.reset(darftOrder));
                    }
                ]
            }
        })
        .state("app.orderStripeSuccess", {
            url: "/orders/:orderId/success?session_id",
            template: stripeSuccessView,
            controller: "order.StripeSuccessController",
            controllerAs: "vm"
        })
        .state("app.orderStripeCancel", {
            url: "/orders/:orderId/cancel",
            template: stripeCancelView
        })
        .state("app.checkout", {
            url: "/orders/:orderId/checkout",
            template: checkoutView,
            controller: "order.CheckoutController",
            controllerAs: "vm",
            resolve: {
                storeSettings: [
                    "common.config", function (cfg) {
                        return cfg.getStoreSettings();
                    }
                ],
                data: [
                    "order.orderService", "$stateParams", function (svc, $stateParams) {
                        return svc.checkout($stateParams.orderId);
                    }
                ]
            }
        });
}
