function RegistrationController(accountSvc, logger, $state) {
  var vm = this;

  vm.userName = "";
  vm.email = "";
  vm.password = "";
  vm.confirmPassword = "";
  vm.customerName = "";
  vm.phoneNumber = "";
  vm.building = "";
  vm.flatNo = "";
  vm.houseNo = "";
  vm.road = "";
  vm.town = "";

  let postCode = "";
  vm.postCode = function (value) {
    if(arguments.length) {
      angular.isDefined(value)
        ? postCode = value.split(' ').join('') 
        : postCode = '';
    } else {
      return postCode;
    }
  };

  vm.registerCustomer = registerCustomer;
  vm.isTermsAccepted = false;

  /////////

  function registerCustomer() {
    let req = {
      userName: vm.userName,
      password: vm.password,
      customerName: vm.customerName,
      building: vm.building,
      flatNo: vm.flatNo,
      houseNo: vm.houseNo,
      road: vm.road,
      town: vm.town,
      postCode: postCode,
      phoneNumber: vm.phoneNumber,
      email: vm.email,
    };

    accountSvc
      .registerCustomer(req)
      .then((res) => {
        logger.showInfo("Registration successful; Confirm your email address.");
        $state.go("app.login");
      })
      .catch((err) => {
        logger.showError(err.data.message);
      });
  }

}

export default ["userMgt.accountService", "common.logger", "$state", RegistrationController];
