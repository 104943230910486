import React from 'react';
import ReactDOM from 'react-dom';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import * as colors from '@mui/material/colors';
import {config} from "app/common/config";
import { withLocalization } from "./with-localization";
import { withReactQuery } from "./with-react-query";

const theme = createTheme({
  palette: {
    primary: {
      main: colors[config.colorPalette][500],
    },
  },
});

function withAngular(ReactComponent, bindings = {}, deps = []) {
  const Component = withReactQuery(withLocalization(ReactComponent));
  class Controller {
    constructor($element, ...injectedDeps) {
      this.$element = $element;
      this.services = deps.reduce((acc, cv, ci) => ({...acc, [cv]: injectedDeps[ci]}), {});
      this.$element.on('$destroy', () => {
        ReactDOM.unmountComponentAtNode(this.$element[0]);
      });
    }

    $onChanges() {
      const comp = this;
      const binds = Object.keys(bindings).reduce((acc, cv) => ({...acc, [cv]: comp[cv]}), {});
      const props = Object.assign(binds, this.services);
      ReactDOM.render(
        <ThemeProvider theme={theme}>
          <Component {...props} />
        </ThemeProvider>,
        this.$element[0]
      );
    }
  }

  Controller.$inject = ['$element', ...deps];

  return {
    transclude: false,
    bindings,
    controller: Controller,
  };
}

export default withAngular;