function HomeController(config) {
  var vm = this;
  vm.interval = 8000;
  vm.noWrapSlides = false;
  vm.slides = [
    {
      image: "assets/img/slide1.jpg",
      text: "The food is great..!"
    },
    {
      image: "assets/img/slide2.jpg",
      text: "Holly Cow"
    },
    {
      image: "assets/img/slide3.jpg",
      text: "Holly Cow"
    },
    {
      image: "assets/img/slide4.jpg",
      text: "Holly Cow"
    }
  ];
}

export default [
  "common.config",
  HomeController
];