import React from 'react';
import { Grid } from '@mui/material';
import { StoreCalendar } from '../StoreCalendar';

export default function StoreHoursTab({ tabValue }) {
  if (tabValue !== 3) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StoreCalendar />
      </Grid>
    </Grid>
  );
}
