function ResetPasswordController(accountSvc, logger, $stateParams) {
  var vm = this;
  vm.userId = $stateParams.userId;
  vm.token = $stateParams.token;

  vm.message = "";
  vm.error = "";

  confirmEmail();

  function confirmEmail() {
    var req = { userId: vm.userId, token: vm.token };
    accountSvc
      .confirmEmail(req)
      .then(() => {
        vm.message = "Your email address is confirmed";
      })
      .catch((err) => {
        vm.error = "Could not confirm email.";
      });
  }
}

export default [
  "userMgt.accountService",
  "common.logger",
  "$stateParams",
  ResetPasswordController
];
