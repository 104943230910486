function addToken(currentUsr, $q) {
  function request(config) {
    if (currentUsr.isLoggedIn()) {
      config.headers.Authorization = `Bearer ${currentUsr.getToken()}`;
    }
    return $q.when(config);
  }

  return {
    request
  };
}

export default ['security.currentUser', '$q', addToken];
