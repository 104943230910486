import dayjs from 'dayjs';

export default class ReportController {
  constructor(oauth, loginRedirect, logger, settings, $http, config, $timeout, $mdSidenav) {
    this.oauth = oauth;
    this.loginRedirect = loginRedirect;
    this.logger = logger;
    this.settings = settings;
    this.reportUrl = `${config.apiUrl}/reports`;
    this.$http = $http;
    this.$timeout = $timeout;
    this.$mdSidenav = $mdSidenav;
    this.loading = false;
    this.message = "";
    this.report = "";
    this.reportTitle = "";
    this.reportParams = {
      dateFrom: new Date(),
      dateTo: new Date()
    };

    this.init();
  }

  showDailySales() {
    this.reportTitle = "Daily Sales";
    this.loading = true;
    this.getReport('sales/daily', {
      date: dayjs(this.reportParams.dateFrom).startOf('day').toISOString()
    })
      .then(resp => this.report = resp.data)
      .finally(() => {
        this.loading = false;
        this.toggleSidenav();
      });
  }

  showDetailSummary() {
    this.reportTitle = "Detail Summary";
    this.loading = true;
    this.getReport('sales/summary', {
      dateFrom: dayjs(this.reportParams.dateFrom).startOf('day').toISOString(),
      dateTo: dayjs(this.reportParams.dateTo).startOf('day').toISOString()
    })
      .then(resp => this.report = resp.data)
      .finally(() => {
        this.loading = false;
        this.toggleSidenav();
      });
  }

  showSalesByCategory() {
    this.reportTitle = "Sales By Category";
    this.loading = true;
    this.getReport('sales/by/category', {
      dateFrom: dayjs(this.reportParams.dateFrom).startOf('day').toISOString(),
      dateTo: dayjs(this.reportParams.dateTo).startOf('day').toISOString()
    })
      .then(resp => this.report = resp.data)
      .finally(() => {
        this.loading = false;
        this.toggleSidenav();
      });
  }

  showTimeCard() {
    this.reportTitle = "Time Card";
    this.loading = true;
    this.getReport('timecard', {
      dateFrom: dayjs(this.reportParams.dateFrom).startOf('day').toISOString(),
      dateTo: dayjs(this.reportParams.dateTo).startOf('day').toISOString()
    })
      .then(resp => this.report = resp.data)
      .finally(() => {
        this.loading = false;
        this.toggleSidenav();
      });
  }

  init() {
    this.showDailySales();
  }

  getReport(url, params) {
    return this.$http({
      url: `${this.reportUrl}/${url}`,
      params: params,
      method: 'GET',
      responseType: 'text'
    });
  }

  toggleSidenav() {
    this.$mdSidenav("report-left-sidenav").toggle();
  }
}

ReportController.$inject = [
  "security.oauth",
  "security.loginRedirect",
  "common.logger",
  "settings",
  "$http",
  "common.config",
  "$timeout",
  "$mdSidenav",
];
