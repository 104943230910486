import React from "react";
import {
  Toolbar,
  AppBar,
  Typography,
} from "@mui/material";

export const Topbar = React.forwardRef(({ title }, ref) => (
  <AppBar ref={ref} position="static">
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
    </Toolbar>
  </AppBar>
))

