import React from "react";
import { Controller } from "react-hook-form";
import {
  Switch,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Legend } from './StyledComponents';

export default function ServiceActivationAndDefaultSelector({
  control,
  setValue,
  watch,
}) {

  const selectedValue = watch("isCollectionDefault") ? "collection" : "delivery";

  const handleStatusChange = (field) => (event) => {
    setValue(field, event.target.checked);
  };

  const handleDefaultChange = (event) => {
    const value = event.target.value;
    if (value === "collection") {
      setValue("isCollectionDefault", true);
      setValue("isDeliveryDefault", false);
    } else {
      setValue("isCollectionDefault", false);
      setValue("isDeliveryDefault", true);
    }
  };

  return (
    <>
      <FormGroup sx={{ pt: '10px' }}>
        <Legend>Active Services</Legend>
        <FormControlLabel
          control={
            <Controller
              name="isCollectionActive"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={handleStatusChange("isCollectionActive")}
                  color="primary"
                />
              )}
            />
          }
          label="Collection"
        />

        <FormControlLabel
          control={
            <Controller
              name="isDeliveryActive"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={handleStatusChange("isDeliveryActive")}
                  color="primary"
                />
              )}
            />
          }
          label="Delivery"
        />
      </FormGroup>

      <FormGroup sx={{ pt: '10px' }}>
        <Legend>Default Service</Legend>
        <RadioGroup
          value={selectedValue}
          onChange={handleDefaultChange}
          row
        >
          <FormControlLabel
            value="collection"
            control={<Radio />}
            label="Collection"
          />
          <FormControlLabel
            value="delivery"
            control={<Radio />}
            label="Delivery"
          />
        </RadioGroup>
      </FormGroup>
    </>
  );
}
