import React from "react";
import {
  Switch,
  Grid,
  FormControlLabel,
} from "@mui/material";


export function Day({day, onChange}) {
  const handleChange = (e) => onChange({nameOfDay: day.nameOfDay, checked: e.target.checked});
  return (
    <React.Fragment>
      <Grid item>
        {day.nameOfDay}
      </Grid>
      <Grid item>
        <FormControlLabel control={
          <Switch
            checked={day.isOpen}
            onChange={handleChange}
            inputProps={{'aria-label': 'Open?'}}
          />
        } label={day.isOpen ? 'Open' : 'Close'}/>
      </Grid>
    </React.Fragment>
  );
}
