function AccountService($http, config) {
  return {
    registerCustomer : registerCustomer,
    resetPassword : resetPassword,
    sendPasswordResetLink : sendPasswordResetLink,
    getUserInfo : getUserInfo,
    confirmEmail : confirmEmail,
    changeAddress : changeAddress,
  };

  function changeAddress(req) {
    return $http.post(`${config.apiUrl}/accounts/current/address`, req);
  }

  function confirmEmail(req) {
    return $http.post(`${config.apiUrl}/accounts/confirm-email`, req);
  }

  function sendPasswordResetLink(req) {
    return $http.post(`${config.apiUrl}/accounts/send-password-reset-link`, req);
  }

  function resetPassword(req) {
    return $http.post(`${config.apiUrl}/accounts/reset-password`, req);
  }

  function registerCustomer(req) {
    return $http.post(`${config.apiUrl}/accounts/register`, req);
  }

  function getUserInfo() {
    return $http.get(`${config.apiUrl}/accounts/current`)
      .then((response) => {
        return response.data;
      });
  }
}

export default ["$http", "common.config", AccountService];
