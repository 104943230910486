function reloadLatest($q, $window) {
  var responseError = function (response) {
    if (response.status === 418) {
      $window.location.reload(true);
    }
    return $q.reject(response);
  };

  return {
    responseError
  };
}

export default ["$q", "$window", reloadLatest];
