import {ReservationView} from "./Reservation";
import {SuccessView} from "./Reservation/SuccessView";
import withAngular from "../common/with-angular";

export default angular
.module("reservation", ["ui.router"])
.component("xsReservationView", withAngular(ReservationView, [], ['$state']))
.component("xsReservationSuccessView", withAngular(SuccessView))
.config(["$stateProvider", "$urlRouterProvider", Config]);

function Config($stateProvider) {
  $stateProvider
  .state("app.reservationSuccess", {
    url: "/reservation/success",
    component: 'xsReservationSuccessView',
  })
  .state("app.reservation", {
    url: "/reservation",
    component: 'xsReservationView'
  });
}
