function hasLocalStorage() {
  let test = `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At`;
  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

class MemoryStore {
  constructor() {
    this._store = {};
  }

  getItem(key) {
    return this._store[key];
  }

  getObject(key) {
    return this._store[key] || {};
  }

  setItem(key, value) {
    this._store[key] = value;
  }

  removeItem(key) {
    delete this._store[key];
  }
}

export default class SessionStore {
  constructor() {
    this._store = hasLocalStorage() ? window.localStorage : new MemoryStore();
  }

  getItem(key) {
    return this._store.getItem(key);
  }

  getObject(key) {
    return JSON.parse(this._store.getItem(key) || '{}');
  }

  setItem(key, value) {
    return this._store.setItem(key, value);
  }

  setObject(key, value) {
    return this._store.setItem(key, value);
  }

  removeItem(key) {
    return this._store.removeItem(key);
  }
}