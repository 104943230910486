function OrderService($q, $http, config) {
  let menus = [];

  function getMenusByGroup(groupId) {
    if (menus.length === 0) {
      return $http.get(`${config.apiUrl}/menus`)
        .then((response) => {
          menus = response.data;
          return filterMenusByGroup(menus, groupId);
        });
    }
    return $q.when(filterMenusByGroup(menus, groupId));
  }

  function filterMenusByGroup(_menus, groupId) {
    return _menus.filter((m) => {
      return m.groupId === groupId;
    });
  }

  function getAllGroups() {
    return $http.get(`${config.apiUrl}/menus/groups`)
      .then((response) => {
        return response.data;
      });
  }

  function placeOrder(req) {
    return $http.post(`${config.apiUrl}/orders`, req)
      .then((response) => {
        return response.data;
      });
  }
  
  function success(orderId, sessionId) {
    return $http.put(`${config.apiUrl}/orders/${orderId}/success`, {sessionId})
      .then((response) => {
        return response.data;
      });
  }
  
  function getDraftOrder() {
    return $http.get(`${config.apiUrl}/orders/draft`)
        .then((response) => {
          return response.data;
        });
  }

  function addLine(line) {
    return $http.post(`${config.apiUrl}/orders/${line.orderId}/lines`, line)
        .then(res => res.data);
  }
  
  function addPostCode(req) {
    return $http.patch(`${config.apiUrl}/orders/${req.orderId}/address`, req)
        .then(res => res.data);
  }
  
  function reduceQuantity(data) {
    return $http.patch(`${config.apiUrl}/orders/${data.orderId}/lines/${data.lineId}`, data)
        .then(res => res.data);
  }
  
  function removeModifier(data) {
    return $http.delete(`${config.apiUrl}/orders/${data.orderId}/lines/${data.lineId}/modifiers/${data.lineModifierId}`, data)
        .then(res => res.data);
  }
  
  function update(data) {
    return $http.patch(`${config.apiUrl}/orders/${data.orderId}`, data)
        .then(res => res.data);
  }
  
  function checkout(id) {
    return $http.post(`${config.apiUrl}/orders/${id}/checkout`, { orderId: id})
        .then((response) => {
          return response.data;
        });
  }
  
  function updateAddress(data) {
    return $http.put(`${config.apiUrl}/orders/${data.orderId}/address`, data)
        .then((response) => {
          return response.data;
        });
  }
  
  function updatePersonalInfo(data) {
    return $http.put(`${config.apiUrl}/orders/${data.orderId}/customer`, data)
        .then((response) => {
          return response.data;
        });
  }
  
  function applyDiscount(data) {
    return $http.put(`${config.apiUrl}/orders/${data.orderId}/discount`, data)
        .then((response) => {
          return response.data;
        });
  }

  function applyCoupon(data) {
    return $http.put(`${config.apiUrl}/orders/${data.orderId}/coupon`, data)
        .then((response) => {
          return response.data;
        });
  }

  return {
    getAllGroups,
    getMenusByGroup,
    addLine,
    addPostCode,
    reduceQuantity,
    removeModifier,
    update,
    updateAddress,
    updatePersonalInfo,
    getDraftOrder,
    placeOrder,
    success,
    checkout,
    applyDiscount,
    applyCoupon
  };
}

export default ["$q", "$http", "common.config", OrderService];
