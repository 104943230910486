import React from 'react';
import { Grid, TextField, FormControlLabel, Checkbox, Button, FormGroup, styled } from '@mui/material';
import { Controller } from 'react-hook-form';
import CloseStoreSwitch from './CloseStoreSwitch';
import ServiceActivationAndDefaultSelector from './ServiceActivationAndDefaultSelector';
import { Legend } from './StyledComponents';

const ServicesTab = ({ tabValue, control, errors, watch, setValue, isSaving, isSuccess, message }) => {
  if (tabValue !== 0) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormGroup sx={{ p: '5x' }}>
          <Legend>Service Time</Legend>
          <Grid container spacing={2} sx={{ pt: '10px' }}>
            <Grid item xs={12}>
              <Controller
                name="collectionWaitingTime"
                control={control}
                rules={{
                  required: "Collection time is required",
                  min: { value: 1, message: "Minimum collection time is 1 minute" }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    onChange={(e) => field.onChange(e.target.value)}
                    label="Collection Time"
                    error={!!errors.collectionWaitingTime}
                    helperText={errors.collectionWaitingTime?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="deliveryWaitingTime"
                control={control}
                rules={{
                  required: "Delivery time is required",
                  min: { value: 1, message: "Minimum delivery time is 1 minute" }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    onChange={(e) => field.onChange(e.target.value)}
                    label="Delivery Time"
                    error={!!errors.deliveryWaitingTime}
                    helperText={errors.deliveryWaitingTime?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup sx={{ p: '5x' }}>
          <Legend>Service Status</Legend>
          <Controller
            name="isGuestOrderActive"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={field.onChange}
                  />
                }
                label="Allow guest orders"
              />
            )}
          />
          <Controller
            name="isReservationActive"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                  />
                }
                label="Reservation active?"
              />
            )}
          />
          <Controller
            name="allowPreOrder"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={field.onChange}
                  />
                }
                label="Pre-order active?"
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <CloseStoreSwitch
          watch={watch}
          setValue={setValue}
        />
      </Grid>


      <Grid item xs={12}>
        <ServiceActivationAndDefaultSelector
          control={control}
          setValue={setValue}
          watch={watch}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Submit
        </Button>
      </Grid>
      {
        message && (
          <Grid item xs={12}>
            <Typography color={isSuccess ? "primary" : "error"}>
              {message}
            </Typography>
          </Grid>
        )
      }

    </Grid >
  );
};

export default ServicesTab;
