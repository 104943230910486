function DiscountService($http, config) {
  function getAllDiscounts() {
    return $http.get(`${config.apiUrl}/discounts`)
      .then((res) => {
        return res.data;
      });
  }

  return {
    getAllDiscounts : getAllDiscounts
  };
}

export default ["$http", "common.config", DiscountService];
