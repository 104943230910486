import template from './view/menu-item.html';

function DirectiveCtrl($scope) {
  const images = {
    'chilli': '',
    'vegetarian': '',
    'peanut': '',
    'celery': '',
    'crustaceans': '',
    'dairy': '',
    'egg': '',
    'fish': '',
    'gluten': '',
    'molluscs': '',
    'mustard': '',
    'sesame': '',
    'soya': '',
    'vegan': '',
    'nuts': '',
    'sulphite': '',
    'lupins': ''
  };

  Object
    .keys(images)
    .forEach(x => {
      let imageKey = x;
      import( /* webpackMode: "eager" */ `../img/menu-images/${x}.png`)
        .then(img => images[imageKey] = img.default);
    });

  $scope.getImage = (imageName) => images[`${imageName}`];
  $scope.addLine = (event, menu, quantity) => {
    $scope.ctrlAddLine({
      event: event,
      menu: menu,
      quantity: quantity
    });
  };
}

function MenuItemDirective() {
  return {
    scope: {
      menus: '=',
      ctrlAddLine: '&addLine'
    },
    template: template,
    controller: ['$scope', DirectiveCtrl]
  };
}

export default MenuItemDirective;
