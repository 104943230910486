import logger from "./logger";
import {config} from "./config";
import encoder from "./encoder";

function compareTo() {
  return {
    require: "ngModel",
    scope: {
      otherModelValue: "=compareTo"
    },
    link: function (scope, element, attributes, ngModel) {

      ngModel.$validators.compareTo = function (modelValue) {
        return modelValue == scope.otherModelValue;
      };

      scope.$watch("otherModelValue", function () {
        ngModel.$validate();
      });
    }
  };
}

export default angular
.module("common", ["ngMaterial"])
.factory("common.config", ["$q", "$window", function ($q, $window) {
  const getStoreSettings = () => $q.when(config);
  return {
    apiUrl: config.apiUrl,
    paymentProvider: config.paymentProvider,
    getStoreSettings
  };
}])
.factory("common.logger", logger)
.factory("common.encoder", encoder)
.directive("compareTo", compareTo);