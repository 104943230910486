function LoginDialogController(oauth, logger, $mdDialog, $state, accountSvc, currentUser, settings) {
  const vm = this;

  vm.userName = "";
  vm.password = "";

  vm.login = login;
  vm.cancel = cancel;
  vm.goToForgotPassword = goToForgotPassword;
  vm.registerCustomer = registerCustomer;
  vm.loginAsGuest = loginAsGuest;
  vm.isTermsAccepted = true;
  vm.selectedTab = 0;
  vm.settings = settings;
  
  vm.reg = {
    userName: "",
    email: "",
    password: "",
    customerName: "",
    phoneNumber: "",
    building: "",
    flatNo: "",
    houseNo: "",
    road: "",
    town: ""
  };

  let postCode = "";
  vm.reg.postCode = function (value) {
    if(arguments.length) {
      angular.isDefined(value)
        ? postCode = value.split(' ').join('') 
        : postCode = '';
    } else {
      return postCode;
    }
  };


  /////////

  function registerCustomer() {
    let req = {
      userName: vm.reg.userName,
      password: vm.reg.password,
      customerName: vm.reg.customerName,
      building: vm.reg.building,
      flatNo: vm.reg.flatNo,
      houseNo: vm.reg.houseNo,
      road: vm.reg.road,
      town: vm.reg.town,
      postCode: postCode,
      phoneNumber: vm.reg.phoneNumber,
      email: vm.reg.email,
    };

    return accountSvc
      .registerCustomer(req)
      .then(_ => oauth.login(req.email, req.password))
      .then(_ => {
        $mdDialog.hide();
      })
      .catch(err => {
        logger.showError(createMessage(err, 'Error: could not register the user'));
      });
  }


  function cancel() {
    $mdDialog.cancel();
  }

  function goToForgotPassword($event) {
      $event && $event.preventDefault();
      
    $state.go("app.forgotPassword");
    $mdDialog.hide();
  }

  function loginAsGuest() {
    return oauth
      .loginGuest()
      .then(_ => {
        $mdDialog.hide();
      })
      .catch((err) => {
        logger.showError(createMessage(err, 'Error: could not log you in'));
      });
  }


  function login(form) {
    if (!form.$valid) {
      logger.showError("Invalid user name or password");
      return false;
    }

    return oauth
      .login(vm.userName, vm.password)
      .then(_ => {
        $mdDialog.hide();
      })
      .catch((err) => {
        logger.showError(createMessage(err, 'Error: could not log you in'));
      });
  }

  function createMessage(err, msg) {
    return err.data.message
      ? err.data.message
      : `Error: ${msg}`;
  }
}

export default [
  "security.oauth",
  "common.logger",
  "$mdDialog",
  "$state",
  "userMgt.accountService",
  "security.currentUser",
  "settings", // get's resolved from app level parent route
  LoginDialogController
];
