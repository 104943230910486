import React from 'react';
import { Grid, Button } from '@mui/material';
import PaymentTypeToggle from './PaymentTypeToggle';

const PaymentTypesTab = ({ tabValue, control, setValue, watch, isSaving, isSuccess, message }) => {
  if (tabValue !== 2) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PaymentTypeToggle
          control={control}
          setValue={setValue}
          watch={watch}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSaving}
        >
          Submit
        </Button>
      </Grid>
      {message && (
        <Grid item xs={12}>
          <Typography color={isSuccess ? "primary" : "error"}>
            {message}
          </Typography>
        </Grid>
      )}

    </Grid>
  );
};

export default PaymentTypesTab;
