import LoginController from "./login-controller";
import RegistrationController from "./registration-controller";
import AccountService from "./account-service";
import ForgotPasswordController from "./forgot-password-controller";
import ResetPasswordController from "./reset-password-controller";
import ConfirmEmailController from "./confirm-email-controller";

import confirmEmailView from "./view/confirm-email.html";
import forgotPasswordView from "./view/forgot-password.html";
import loginView from "./view/login.html";
import registrationView from "./view/registration.html";
import resetPasswordView from "./view/reset-password.html";
import { ProfileView } from "./Profile";
import withAngular from "../common/with-angular";

export default angular
  .module("userMgt", ["ui.router", "security", "common"])
  .component("xsProfileView", withAngular(ProfileView, [], ['$state']))
  .controller("userMgt.LoginController", LoginController)
  .controller("userMgt.RegistrationController", RegistrationController)
  .controller("userMgt.ForgotPasswordController", ForgotPasswordController)
  .controller("userMgt.ResetPasswordController", ResetPasswordController)
  .controller("userMgt.ConfirmEmailController", ConfirmEmailController)
  .service("userMgt.accountService", AccountService)
  .config(["$stateProvider", "$urlRouterProvider", Config]);

function Config($stateProvider, $urlRouterProvider) {
  $stateProvider
    .state("app.login", {
      url: "/login",
      template: loginView,
      controller: "userMgt.LoginController",
      controllerAs: "vm"
    })
    .state("app.logout", {
      url: "/logout",
      template: '<div style="text-align: center; font-size: 2em;">You are logged out!</div>',
      resolve: {
        signOutInfo: [
          "security.oauth", function (oauth) {
            return oauth.signOut();
          }
        ]
      }
    })
    .state("app.account", {
      url: "/account",
      component: "xsProfileView",
    })
    .state("app.registration", {
      url: "/registration",
      template: registrationView,
      controller: "userMgt.RegistrationController",
      controllerAs: "vm"
    })
    .state("app.forgotPassword", {
      url: "/forgot-password",
      template: forgotPasswordView,
      controller: "userMgt.ForgotPasswordController",
      controllerAs: "vm"
    })
    .state("app.resetPassword", {
      url: "/reset-password/{userId:int}/{code:string}",
      template: resetPasswordView,
      controller: "userMgt.ResetPasswordController",
      controllerAs: "vm"
    })
    .state("app.confirmEmail", {
      url: "/confirm-email/{userId:int}/{token:string}",
      template: confirmEmailView,
      controller: "userMgt.ConfirmEmailController",
      controllerAs: "vm"
    });
}
