import React from "react";
import CloseIcon from '@mui/icons-material/Close';

import {
  IconButton,
  Grid,
} from "@mui/material";

export function Hour({day, hour, onDelete}) {
  const handleDelete = () => onDelete({nameOfDay: day.nameOfDay, hour: hour});
  return (
    <Grid container item direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      <Grid item>
        <span>{String(hour.start.hour).padStart(2, "0")}:{String(hour.start.minute).padStart(2, "0")}-{String(hour.end.hour).padStart(2, "0")}:{String(hour.end.minute).padStart(2, "0")}</span>
      </Grid>
      <Grid item>
        <IconButton aria-label="delete hours" onClick={handleDelete}>
          <CloseIcon/>
        </IconButton>
      </Grid>
    </Grid>
  );
}


