import React from "react";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';


export function withReactQuery(Component) {
  const queryClient = new QueryClient();
  return (props) => (
    <QueryClientProvider client={queryClient}>
      <Component {...props} />
    </QueryClientProvider>
  );
}