import ReportController from "./report-controller";
import  withAngular from "app/common/with-angular";
import { Settings } from "./Settings";

import reportView from "./report.html";

export default angular
  .module("admin", ["ui.router", "security", "common"])
  .component("xsSettingsView", withAngular(Settings))
  .controller("admin.ReportController", ReportController)
  .config(["$stateProvider", "$urlRouterProvider", Config]);

function Config($stateProvider) {
  $stateProvider
    //.state("app.admin", {
    //  url: "/admin",
    //  template: adminView,
    //  controller: "admin.AdminController",
    //  resolve: {
    //    "storeSettings": ["common.config", "$http", function (config, $http) {
    //      const url = `${config.apiUrl}/stores/default/settings/backoffice`;
    //      return $http.get(url).then(res => res.data);
    //    }]
    //  },
    //  controllerAs: "vm"
    //})
    .state("app.admin", {
      url: "/admin",
      component: 'xsSettingsView',
    })
    .state("app.reports", {
      url: "/reports",
      template: reportView,
      controller: "admin.ReportController",
      controllerAs: "vm"
    });
}


