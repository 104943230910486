function OAuth($http, $log, currentUser, accountSvc, commonCfg) {
  function signOut() {
    currentUser.signOut();
  }

  function login(userName, password) {
    const body = formEncode({
      userName: userName,
      password: password,
      client_id: "xencube_web",
      grant_type: "password"
    });

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };

    return $http.post(`${commonCfg.apiUrl}/token`, body, config)
      .then((response) => {
        currentUser.reset(response.data);
      });
  }
  
  function loginGuest() {
    
    const body = formEncode( {
      userName: "guest",
      password: "guest_guest",
      client_id: "xencube_web",
      grant_type: "password"
    });
    

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };

    return $http.post(`${commonCfg.apiUrl}/token`, body, config)
        .then((response) => {
          currentUser.reset(response.data);
        });
  }

  function formEncode(data) {
    const pairs = [];
    Object.keys(data)
      .forEach(key => {
        pairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
      });
    return pairs.join("&").replace(/%20/g, "+");
  }

  return {
    login,
    loginGuest,
    signOut
  };
}

export default ["$http", "$log", "security.currentUser", "userMgt.accountService", "common.config", OAuth];
