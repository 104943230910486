import React from 'react';
import { Box, Grid } from '@mui/material';
import { ReservationCalendar } from '../ReservationCalendar';

export default function ReservationHoursTab({ tabValue }) {
  if (tabValue !== 4) {
    return null;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ReservationCalendar />
      </Grid>
    </Grid>
  );
}
