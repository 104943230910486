export default class UpdatePersonalInfoDialogController {
  constructor($mdDialog, $state, order) {
    this.guest = {
      email: order.customer.email,
      fullName: order.customer.fullName,
      phoneNumber: order.customer.mobilePhone
    };
    this.$mdDialog = $mdDialog;
    this.$state = $state;
    this.isTermsAccepted = true;
  }

  updateInfo(form) {
    if (!form.$valid) {
      return false;
    }
    this.$mdDialog.hide(this.guest);
  }

  cancel() {
    this.$mdDialog.cancel();
  }

}

UpdatePersonalInfoDialogController.$inject = [
  "$mdDialog",
  "$state",
  "order"
];
